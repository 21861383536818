import React from 'react'
import { Formik, FormikActions, Form } from 'formik'
import * as Yup from 'yup'
import { Box, Button, Heading, Image, Spinner, Flex } from '@chakra-ui/core'

import authentication from 'lib/api/authentication'
import { RouteComponentProps } from 'react-router'
import FormInput from 'lib/components/FormInput'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, 'Login or Email must be at least 2 characters')
    .email()
    .required('Required'),
  password: Yup.string()
    .min(2, 'Password must be at least 2 characters')
    .required('Required'),
})

interface IFormValues {
  email: string
  password: string
}

export default function LoginScreen(props: RouteComponentProps) {
  const onLogin = async (
    values: IFormValues,
    bag: FormikActions<IFormValues>,
  ) => {
    try {
      await authentication().signInWithEmailAndPassword(
        values.email,
        values.password,
      )
    } catch (e) {
      bag.setSubmitting(false)
    }
  }

  return (
    <Flex p={3} direction="column" justify="center" align="center">
      <Flex
        rounded="lg"
        p={4}
        width={300}
        direction="column"
        align="center"
        bg="white"
      >
        <Image src="images/icon.png" width={100} height={100} />
        <Heading size="xl" my={2} textAlign="center">
          Log In
        </Heading>

        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={loginSchema}
          onSubmit={onLogin}
        >
          {({ values, handleChange, isSubmitting }) => (
            <Form
              style={{ width: '100%' }}
              noValidate={true}
              data-test="login-form"
            >
              <Box my={4}>
                <FormInput
                  label="Email"
                  name="email"
                  placeholder="jonsnow@example.com"
                  onChange={handleChange}
                  value={values.email}
                />
                <FormInput
                  label="Password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password}
                  type="password"
                />

                <Flex direction="column" justify="center">
                  {isSubmitting && <Spinner />}
                  {!isSubmitting && (
                    <Button variantColor="blue" type="submit">
                      Submit
                    </Button>
                  )}
                </Flex>
              </Box>
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  )
}
