import React from 'react'

import List from 'lib/components/List'
import { RouteComponentProps } from 'react-router'
import BookRow from 'domains/books/comonents/BookRow'
import { Box } from '@chakra-ui/core'
import Paper from 'lib/components/Paper'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle';

const order = (ref: firebase.firestore.CollectionReference) =>
  ref.orderBy('title', 'asc')

export default function BooksScreen(props: RouteComponentProps) {
  return (
    <Box p={3}>
      <DocumentTitle title="All Books" />
      <Paper title="books">
        <List
          collection="books"
          RowComponent={BookRow}
          query={order}
          searchIndex="books"
        />
      </Paper>
    </Box>
  )
}
