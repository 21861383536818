import db, { getCollection } from 'lib/api/db'
import IBookFile from 'domains/books/models/IBookFile'
import { deleteBookFile } from 'domains/books/workflows/deleteBookFile'
import IAuthor from 'domains/authors/models/IAuthor'
import { deleteEntity } from 'lib/api/entities/deleteEntity'

export const deleteBook = async (id: string) => {
  const bookPath = `books/${id}`

  const bookAuthorsPath = `${bookPath}/authors`
  const bookAuthors = await getCollection(bookAuthorsPath)
  for (const author of bookAuthors as IAuthor[]) {
    await db.doc(`authors/${author.id}/books/${id}`).delete()
    await db.doc(`books/${id}/authors/${author.id}`).delete()
  }

  const bookFilesPath = `${bookPath}/files`
  const bookFiles = await getCollection(bookFilesPath)
  for (const file of bookFiles as IBookFile[]) {
    await deleteBookFile(file)
    await db.doc(`books/${id}/files/${file.id}`).delete()
  }

  await deleteEntity('books', id)
}
