import React, { PureComponent } from 'react'
import { connect, FormikContext } from 'formik'
import * as R from 'ramda'
import { Box } from '@chakra-ui/core'

import { hasValue } from 'lib/utils'

interface IValues {
  [s: string]: any
}
interface IFormikProps {
  formik: FormikContext<IValues>
}
interface IOuterProps {
  name: string
  showImmediately?: boolean
  showRequiredOnlyOnSumbit?: boolean
}
class FormErrorMessage extends PureComponent<
  IFormikProps & IOuterProps
> {
  render() {
    const {
      formik: { submitCount, touched, errors },
      name,
      showImmediately = false,
      showRequiredOnlyOnSumbit = false,
    } = this.props

    const hasSubmitted = submitCount > 0
    const error = R.propOr<string, object, string>('', name, errors)
    const shouldShow = showImmediately
      ? true
      : showRequiredOnlyOnSumbit && (error !== 'Required' || hasSubmitted)
      ? true
      : R.prop(name, touched)

    if (hasValue(error) && (shouldShow || hasSubmitted)) {
      return (
        <Box color="red.500" fontSize="xs" data-test={`form-error-${name}`}>
          {error}
        </Box>
      )
    }
    return null
  }
}

export default connect<IOuterProps>(FormErrorMessage)
