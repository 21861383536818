import React, { useState } from 'react'
import { Box } from '@chakra-ui/core'

import Dialog from 'lib/components/Dialog'
import { deleteBook } from 'domains/books/workflows/deleteBook'

interface IProps {
  id: string
  children: any
  afterDelete?: () => any
}

export default function DeleteBookWrapper(props: IProps) {
  const [isOpen, setIsOpen] = useState(false)
  const onDelete = (e: React.FormEvent<HTMLElement>) => {
    if (!isOpen) {
      e.preventDefault()
      setIsOpen(true)
    }
  }
  const onDeleteConfirmed = async () => {
    await deleteBook(props.id)
    if (props.afterDelete) {
      props.afterDelete()
    }
  }
  const onCloseDelete = () => setIsOpen(false)

  return (
    <Box onClick={onDelete} cursor="pointer">
      {props.children}
      {isOpen && (
        <Dialog
          title={'Delete Book?'}
          message="This will delete all uploaded files..."
          icon="delete"
          confirmVariant="red"
          onConfirm={onDeleteConfirmed}
          onDeny={onCloseDelete}
          isOpen={isOpen}
          doubleConfirm={true}
        />
      )}
    </Box>
  )
}
