import * as R from 'ramda'
import path from 'path'

import { ensureArray } from 'lib/utils/array'

export const ONE_GB_SIZE = 1024 * 1024 * 1024

export const isFileLargerThanOneGB = (fileSize: number) =>
  fileSize >= ONE_GB_SIZE

export const ZIP_EXTENSION = '.zip'
export const PNG_EXTENSION = '.png'
export const JPG_EXTENSION = '.jpg'
export const MP3_EXTENSION = '.mp3'
export const M4A_EXTENSION = '.m4a'

export type FileExtensions = '.zip' | '.png' | '.jpg' | '.mp3' | '.m4a'

export const getFileExtension = (name: string) => path.extname(name)

export const hasFileExtension = (
  name: string,
  ext: FileExtensions | FileExtensions[],
  isFBUrl = false,
) => {
  const check = isFBUrl
    ? (extension: FileExtensions) => name.includes(extension)
    : (extension: FileExtensions) => getFileExtension(name) === extension
  return R.any(check)(ensureArray(ext))
}
