import { useEffect, useState } from 'react'

import db from 'lib/api/db'
import { firestore } from 'firebase'

export default function useCollection(
  path: string,
  query?: (ref: firestore.CollectionReference) => firestore.Query,
): [any[], boolean] {
  const [pending, setPending] = useState(true)
  const [collection, setCollection] = useState<any[]>([])
  useEffect(() => {
    setPending(true)
    let ref: firestore.CollectionReference | firestore.Query = db.collection(
      path,
    )
    if (query) {
      ref = query(ref as firestore.CollectionReference)
    }
    ref.onSnapshot(snap => {
      const col: any[] = []
      snap.forEach(doc =>
        col.push({
          id: doc.id,
          ...doc.data(),
        }),
      )
      setCollection(col)
      setPending(false)
    })
  }, [])

  return [collection, pending]
}
