import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Spinner, Heading } from '@chakra-ui/core'

import IBook from 'domains/books/models/IBook'
import Row from 'lib/components/Row'
import { dotJoinItems } from 'lib/utils/strings'
import { formatDate } from 'lib/utils/date'
import useDoc from 'lib/api/hooks/useDoc'

export default function BookRow(props: IBook) {
  const [data] = useDoc(`books/${props.id}`)

  if (!data) {
    return <Spinner />
  }
  const book = data as IBook
  return (
    <Row>
      <Box flex={1} height="100%">
        <Link to={`/books/${book.id}`}>
          <Box p={3}>
            <Heading fontSize={['lg', 'xl']}>{book.title}</Heading>
            <Box mt={2} fontSize="xs">
              {dotJoinItems([
                book.type,
                book.genre,
                `Created: ${formatDate(book.createdAt)}`,
                `Updated: ${formatDate(book.modifiedAt)}`,
              ])}
            </Box>
          </Box>
        </Link>
      </Box>
    </Row>
  )
}
