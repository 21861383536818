import React, { createContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Header from 'domains/application/components/Header'
import AuthorsScreen from 'domains/authors/screens/AuthorsScreen'
import AuthorDetailsScreen from 'domains/authors/screens/AuthorDetailsScreen'
import BookDetailsScreen from 'domains/books/screens/BookDetailsScreen'
import BooksScreen from 'domains/books/screens/BooksScreen'
import { Box } from '@chakra-ui/core'

export interface IDrawerOptions {
  open: boolean
  toggle: () => any
}
export const DrawerContext = createContext<IDrawerOptions | null>(null)

interface IDrawerLinkProps {
  icon: string
  to: string
  title: string
}
export default function MainRouter() {
  return (
    <Box>
      <Header />
      <Switch>
        <Route exact={true} path="/authors" component={AuthorsScreen} />
        <Route
          exact={true}
          path="/authors/:id"
          component={AuthorDetailsScreen}
        />
        <Route exact={true} path="/books" component={BooksScreen} />
        <Route exact={true} path="/books/:id" component={BookDetailsScreen} />
        <Redirect key="redirect" to="/authors" />
      </Switch>
      <ToastContainer />
    </Box>
  )
}
