import IAuthor from 'domains/authors/models/IAuthor'
import { updateEntity } from 'lib/api/entities/updateEntity'
import db, { getCollection } from 'lib/api/db'
import IBook from 'domains/books/models/IBook'

export const editAuthor = async (author: IAuthor) => {
  updateEntity('authors', author)

  const authorPath = `authors/${author.id}`

  const books = await getCollection(`${authorPath}/books`)
  for (const book of books as IBook[]) {
    await db.doc(`books/${book.id}/authors/${author.id}`).update(author)
  }
}
