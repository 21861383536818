import React, { useState } from 'react'
import * as R from 'ramda'
import { Formik, FormikActions, Form } from 'formik'
import * as Yup from 'yup'
import uuid from 'uuid/v4'
import { Box, Text, Spinner, Heading } from '@chakra-ui/core'

import ModalWrapper from 'lib/components/ModalWrapper'
import db from 'lib/api/db'
import IBook from 'domains/books/models/IBook'
import { getFileExtension } from 'lib/utils/files'
import storage from 'lib/api/storage'
import ProgressBar from 'lib/components/ProgressBar'
import { decorateEntity } from 'lib/api/entities/createEntity'
import FileDropzone from 'lib/components/FileDropzone'
import useCollection from 'lib/api/hooks/useCollection'
import FormErrorMessage from 'lib/components/FormErrorMessage'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'

interface IProps {
  book: IBook
  isOpen: boolean
  close: () => any
  files?: File[]
}

interface IFormValues {
  files: File[]
}

const validationSchema = Yup.object().shape({
  files: Yup.mixed().required('Select file(s)'),
})

export default function CreateBookFileModal(props: IProps) {
  const [files, filesPending] = useCollection(`books/${props.book.id}/files`)
  const [progress, setProgress] = useState<number[]>([])

  const onSave = async (
    values: IFormValues,
    bag: FormikActions<IFormValues>,
  ) => {
    const startOrder = files.length + 1
    let uploadedCount = 0
    for (let i = 0; i < values.files.length; i++) {
      const file = values.files[i]
      const filename = `${uuid()}${getFileExtension(file.name)}`
      const storageUrl = `${props.book.id}/${filename}`
      const storageRef = storage.ref(storageUrl)
      const uploadTask = storageRef.put(file)
      uploadTask.on(
        'state_changed',
        snapshot => {
          setProgress(
            R.assocPath(
              [i],
              Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
              ),
            ),
          )
        },
        error => {
          console.error(error) // tslint:disable-line
        },
        async () => {
          uploadedCount++
          const url = await storageRef.getDownloadURL()
          await db.collection(`books/${props.book.id}/files`).add(
            decorateEntity({
              order: startOrder + i,
              name: file.name,
              url,
              bookId: props.book.id,
              filename,
            }),
          )
          if (uploadedCount === values.files.length) {
            bag.setSubmitting(false)
            bag.resetForm()
            props.close()
          }
        },
      )
    }
  }

  return (
    <Formik
      initialValues={{ files: props.files || [] }}
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => {
        const onFilesAdded = (addedFiles: File[]) => {
          setFieldValue('files', addedFiles)
          setProgress(R.repeat(0, values.files.length))
        }
        const accProgress = Math.floor(
          R.reduce<number, number>(R.add, 0, progress) / progress.length,
        )
        const completeCount = progress.filter(R.lte(100)).length
        return (
          <ModalWrapper
            title="Add Book File(s)"
            isOpen={props.isOpen}
            pending={isSubmitting}
            actions={[
              {
                text: 'Add',
                variantColor: 'green',
                onClick: submitForm,
                extra: { type: 'submit' },
              },
              {
                text: 'Cancel',
                variantColor: 'gray',
                onClick: props.close,
                extra: { type: 'button' },
              },
            ]}
          >
            <DocumentTitle
              title={
                isSubmitting
                  ? `Progress: ${accProgress}% (${completeCount}/${values.files.length})`
                  : 'Add book file'
              }
            />
            {filesPending && <Spinner />}
            {!filesPending && (
              <Form>
                {!isSubmitting && (
                  <Box mb={3}>
                    <FileDropzone onFilesAdded={onFilesAdded} />
                    <FormErrorMessage name="files" />
                  </Box>
                )}

                {isSubmitting && values.files.length > 1 && (
                  <Box mb={5}>
                    <Heading size="lg">
                      Overall Progress ({completeCount}/{values.files.length})
                    </Heading>
                    <ProgressBar percent={accProgress} />
                  </Box>
                )}
                {values.files.map((file: File, index: number) => {
                  return (
                    <Box mb={2} key={`file-${file.name}-${index}`}>
                      <Text>{file.name}</Text>
                      {isSubmitting && (
                        <ProgressBar percent={progress[index]} />
                      )}
                    </Box>
                  )
                })}
              </Form>
            )}
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
