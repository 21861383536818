import { getCollection } from 'lib/api/db'
import { deleteEntity } from 'lib/api/entities/deleteEntity'
import IBook from 'domains/books/models/IBook'
import { deleteBook } from 'domains/books/workflows/deleteBook'

export const deleteAuthor = async (id: string) => {
  const authorPath = `authors/${id}`

  const authorBooks = await getCollection(`${authorPath}/books`)
  for (const book of authorBooks as IBook[]) {
    await deleteBook(book.id)
  }

  await deleteEntity('authors', id)
}
