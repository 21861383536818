import { useEffect } from 'react'

export function useDocumentTitle(title: string) {
  useEffect(() => {
    document.title = `Books: ${title}`
  }, [title])
}

interface IProps {
  title: string
}
export function DocumentTitle(props: IProps) {
  useDocumentTitle(props.title)
  return null
}
