import db, { getTimeStamp } from 'lib/api/db'
import { addSearchIndex } from 'lib/api/search'
import { EntityType } from 'lib/api/entities/entityConstants'

export const decorateEntity = (value: any) => ({
  ...value,
  createdAt: getTimeStamp(),
  modifiedAt: getTimeStamp(),
})

export const createEntity = async (
  type: EntityType,
  value: any,
  skipIndex = false,
) => {
  let entity = decorateEntity(value)
  const ref = await db.collection(type).add(entity)
  entity = {
    ...entity,
    id: ref.id,
  }

  if (!skipIndex) {
    await addSearchIndex(type, entity)
  }

  return entity
}
