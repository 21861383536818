import { useEffect, useState } from 'react'

import { SearchIndexes, searchIndex } from 'lib/api/search'
import { hasValue } from 'lib/utils'

export default function useSearchCollection(
  type: SearchIndexes | undefined,
  query: string,
) {
  const [pending, setPending] = useState(true)
  const [collection, setCollection] = useState<any[]>([])
  useEffect(() => {
    const search = async (q: string) => {
      setPending(true)
      const hits = await searchIndex(type as SearchIndexes, q)
      setCollection(hits)
      setPending(false)
    }
    if (hasValue(query) && type) {
      search(query)
    } else {
      setPending(false)
    }
  }, [query])

  return [collection as any[], pending as boolean]
}
