import db from 'lib/api/db'
import IAuthor from 'domains/authors/models/IAuthor'
import { createEntity } from 'lib/api/entities/createEntity'
import IBook from 'domains/books/models/IBook'

export const createBook = async (values: IBook, authors: IAuthor[]) => {
  const book = await createEntity('books', values)
  for (const author of authors) {
    await db.doc(`authors/${author.id}/books/${book.id}`).set(values)
    await db.doc(`books/${book.id}/authors/${author.id}`).set(author)
  }

  return book
}
