import React from 'react'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'

interface IProps {
  percent: number
}

export default function ProgressBar(props: IProps) {
  return <Progress percent={props.percent} />
}
