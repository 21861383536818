import React, { useState } from 'react'
import { Box } from '@chakra-ui/core';

import CreateEditAuthorModal from 'domains/authors/modals/CreateEditAuthorModal'

interface IProps {
  id?: string
  isCreate?: boolean
  children: any
  after?: () => any
}

export default function CreateEditAuthorWrapper(props: IProps) {
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = (e: React.FormEvent<HTMLElement>) => {
    if (!isOpen) {
      e.preventDefault()
      setIsOpen(true)
    }
  }
  const onClose = () => setIsOpen(false)

  return (
    <Box onClick={onOpen} cursor="pointer">
      {props.children}
      {isOpen && (
        <CreateEditAuthorModal
          id={props.id}
          isCreate={props.isCreate}
          isOpen={isOpen}
          close={onClose}
        />
      )}
    </Box>
  )
}
