import * as firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyAC9gxCo2flqKIaAOTLJfMCLSX8b7hFJ-c',
  authDomain: 'books-bro.firebaseapp.com',
  databaseURL: 'https://books-bro.firebaseio.com',
  projectId: 'books-bro',
  storageBucket: 'books-bro.appspot.com',
  messagingSenderId: '631226032466',
  appId: '1:631226032466:web:1a25a8703d1d5306',
}

firebase.initializeApp(firebaseConfig)
