export type FictionBookGenres =
  | 'Adventure'
  | 'Classic'
  | 'Comics/Graphic Novel'
  | 'Contemporary'
  | 'Crime/Detective'
  | 'Fable'
  | 'Fairy Tale'
  | 'Fan Fiction'
  | 'Fantasy'
  | 'Folktale'
  | 'Historical Fiction'
  | 'Horror'
  | 'Humor'
  | 'Legend'
  | 'LitRPG'
  | 'Magical Realism'
  | 'Meta Fiction'
  | 'Mystery'
  | 'Mythology'
  | 'Mythopoeia'
  | 'Picture Book'
  | 'Realistic Fiction'
  | 'Science Fiction'
  | 'Short Story'
  | 'Suspense/Thriller'
  | 'Swashbuckler'
  | 'Tall Tale'
  | 'Western'

export type NonFictionBookGenres =
  | 'Biography'
  | 'Business'
  | 'Essay'
  | 'History'
  | 'Journalism'
  | 'Memoir'
  | 'Narrative'
  | 'Politics'
  | 'Reference'
  | 'Science'
  | 'Self - help'
  | 'Speech'
  | 'Spirituality/Religion'
  | 'Textbook'

export const GenresList = [
  'Adventure',
  'Biography',
  'Business',
  'Classic',
  'Comics/Graphic Novel',
  'Contemporary',
  'Crime/Detective',
  'Essay',
  'Fable',
  'Fairy Tale',
  'Fan Fiction',
  'Fantasy',
  'Folktale',
  'History',
  'Historical Fiction',
  'Horror',
  'Humor',
  'Journalism',
  'Legend',
  'LitRPG',
  'Magical Realism',
  'Memoir',
  'Meta Fiction',
  'Mystery',
  'Mythology',
  'Mythopoeia',
  'Narrative',
  'Picture Book',
  'Politics',
  'Realistic Fiction',
  'Reference',
  'Science',
  'Science Fiction',
  'Self - help',
  'Short Story',
  'Speech',
  'Spirituality/Religion',
  'Suspense/Thriller',
  'Swashbuckler',
  'Tall Tale',
  'Textbook',
  'Western',
]

export type BookGenres = FictionBookGenres & NonFictionBookGenres

export default interface IBook {
  id: string
  title: string
  type: 'Fiction' | 'Nonfiction'
  genre: BookGenres
  createdAt: number
  modifiedAt: number
}
