import { useEffect, useState } from 'react'

import db from 'lib/api/db'

export default function useDoc(path: string) {
  const [data, internalSetData] = useState<any>(null)
  useEffect(() => {
    db.doc(path).onSnapshot(doc => {
      internalSetData({
        id: doc.id,
        ...doc.data(),
      })
    })
  }, [])

  const setData = (d: any) => db.doc(path).update(d)

  return [data, setData]
}
