import React, { useState } from 'react'
import * as R from 'ramda'
import { firestore } from 'firebase'
import {
  Flex,
  Box,
  Input,
  Icon,
  Spinner,
  InputGroup,
  InputLeftElement,
  Heading,
} from '@chakra-ui/core'

import useCollection from 'lib/api/hooks/useCollection'
import { SearchIndexes } from 'lib/api/search'
import useSearchCollection from 'lib/api/hooks/useSearchCollection'
import { hasValue } from 'lib/utils'

interface IProps {
  title?: string
  actions?: any
  collection: string
  query?: (ref: firestore.CollectionReference) => firestore.Query
  keyExtractor?: (item: any) => string
  RowComponent: any
  DividerComponent?: any
  rowProps?: object
  searchIndex?: SearchIndexes
}

function Divider() {
  return <Box ml={3} height="1px" bg="#dedede" />
}

let searchDebounce: NodeJS.Timeout

export default function List(props: IProps) {
  const [query, setQuery] = useState('')
  const [collection, pending] = useCollection(props.collection, props.query)
  const [searchCollection, searchPending] = useSearchCollection(
    props.searchIndex,
    query,
  )

  const onQueryChange = (e: React.FormEvent<HTMLInputElement>) => {
    const q = e.currentTarget.value
    clearTimeout(searchDebounce)
    searchDebounce = setTimeout(() => {
      setQuery(q)
    }, 250)
  }

  const DividerComp = props.DividerComponent || Divider
  const displayPending = hasValue(query) ? searchPending : pending
  const displayCollection = (hasValue(query)
    ? searchCollection
    : collection) as any[]
  return (
    <Box>
      <Flex direction="row" justify="space-between" align="center">
        <Box>
          {hasValue(props.title) && (
            <Flex m={3} my={6} direction="row" align="center">
              <Box width="4px" bg="primary" height="32px" mr={2} />
              <Heading fontSize={['lg', 'xl']}>{props.title}</Heading>
            </Flex>
          )}
        </Box>

        <Flex direction="row" align="center">
          {props.actions || null}
        </Flex>
      </Flex>

      {hasValue(props.searchIndex) && (
        <Box m={3}>
          <InputGroup>
            <InputLeftElement
              children={<Icon name="search" color="gray.300" />}
            />
            <Input
              name="search"
              onChange={onQueryChange}
              placeholder="Search"
            />
          </InputGroup>
        </Box>
      )}

      {displayPending && (
        <Flex justify="center" align="center">
          <Spinner />
        </Flex>
      )}
      {!displayPending && (
        <Box>
          {displayCollection.map((item: any, index: number) => (
            <Box
              key={
                props.keyExtractor
                  ? props.keyExtractor(item)
                  : R.prop('id', item)
              }
            >
              <props.RowComponent {...item} {...(props.rowProps || {})} />
              {index !== collection.length - 1 && <DividerComp />}
            </Box>
          ))}
          {displayCollection.length === 0 && (
            <Flex direction="column" justify="center" align="center" p={4}>
              <Icon fontSize={64} mb={3} name="info-outline" />
              <Box fontSize="xl" textAlign="center">
                Nothing here
              </Box>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  )
}
