import React, { PureComponent } from 'react'
import { Button, Modal, ModalHeader, ModalFooter } from '@chakra-ui/core'
import { Form } from 'formik'

export interface IModalAction {
  text: string
  onClick: () => any
  variantColor?: string
  extra?: object
}
interface IProps {
  isOpen: boolean
  title: string | any
  notice?: string
  actions: IModalAction[]
  children: any
  pending?: boolean
  bodyPadding?: number | string | Array<string | number>
  isForm?: boolean
}

export default class ModalWrapper extends PureComponent<IProps> {
  renderAction = (options: IModalAction, index: number) => (
    <Button
      isLoading={this.props.pending}
      variantColor={options.variantColor || 'blue'}
      onClick={options.onClick}
      ml={[0, index > 0 ? 2 : 0]}
      mt={[2, 0]}
      key={`modal-action-${index}`}
      data-test={`modal-action-${options.text}`}
      aria-label={`${options.text} Modal Button`}
      {...options.extra}
    >
      {options.text}
    </Button>
  )

  renderActions() {
    return this.props.actions.map(this.renderAction)
  }

  render() {
    const { isOpen, title, children, isForm = false } = this.props

    return (
      <Modal size="lg" isOpen={isOpen}>
        <ModalHeader data-test="modal-title">{title}</ModalHeader>
        {isForm && <Form>{children}</Form>}
        {!isForm && children}
        <ModalFooter>{this.renderActions()}</ModalFooter>
      </Modal>
    )
  }
}
