import * as R from 'ramda'
import { hasValue } from 'lib/utils'

export const camelWrap = (str: string) =>
  str.replace(/([a-z])([A-Z])/g, found =>
    found.replace(/([a-z])([A-Z])/g, '$1\u200B$2'),
  )

export const ellipsize = (str: string, size: number, tail = '...') =>
  str.length < size
    ? str
    : `${R.slice(0, size - R.length(R.split('', tail)), str)}${tail}`

export const dotJoinItems = (items: string[]) =>
  items.filter(hasValue).join(' • ')
