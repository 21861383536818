import React from 'react'
import { Box, Button, Spinner } from '@chakra-ui/core'

import { RouteComponentProps } from 'react-router'
import useDoc from 'lib/api/hooks/useDoc'
import { getNavigationProp } from 'lib/utils/withNavigationProps'
import IAuthor from 'domains/authors/models/IAuthor'
import BookRow from 'domains/books/comonents/BookRow'
import DeleteAuthorWrapper from 'domains/authors/components/DeleteAuthorWrapper'
import CreateEditAuthorWrapper from 'domains/authors/components/CreateEditAuthorWrapper'
import { displayAuthorName } from 'domains/authors/utils/authors'
import CreateEditBookWrapper from 'domains/books/comonents/CreateEditBookWrapper'
import Paper from 'lib/components/Paper'
import List from 'lib/components/List'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle';

const order = (ref: firebase.firestore.CollectionReference) =>
  ref.orderBy('title', 'asc')

export default function AuthorDetailsScreen(props: RouteComponentProps) {
  const id = getNavigationProp(props, 'id') as string
  const authorPath = `authors/${id}`
  const [data] = useDoc(authorPath)

  if (!data) {
    return <Spinner />
  }

  const author = data as IAuthor

  const onDeleted = () => props.history.replace('/authors')

  return (
    <Box p={3}>
      <DocumentTitle title={displayAuthorName(author)} />
      <Paper
        title={displayAuthorName(author)}
        actions={[
          <CreateEditBookWrapper
            key="addBook"
            isCreate={true}
            goToBookDetail={true}
            authors={[author]}
          >
            <Button variantColor="green" mr={2}>
              Add Book
            </Button>
          </CreateEditBookWrapper>,
          <CreateEditAuthorWrapper id={author.id} key="editAuthor">
            <Button variantColor="blue" mr={2}>
              Edit
            </Button>
          </CreateEditAuthorWrapper>,
          <DeleteAuthorWrapper
            id={author.id}
            afterDelete={onDeleted}
            key="delete"
          >
            <Button variantColor="red">Delete</Button>
          </DeleteAuthorWrapper>,
        ]}
      >
        <List
          title="Books"
          collection={`${authorPath}/books`}
          RowComponent={BookRow}
          query={order}
        />
      </Paper>
    </Box>
  )
}
