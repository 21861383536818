import React, { useContext } from 'react'
import { Formik, FormikActions, Form } from 'formik'
import * as Yup from 'yup'

import ModalWrapper from 'lib/components/ModalWrapper'
import FormInput from 'lib/components/FormInput'
import IAuthor from 'domains/authors/models/IAuthor'
import IBook, { GenresList, BookGenres } from 'domains/books/models/IBook'
import { RouterContext } from 'domains/application/screens/AppScreen'
import { RouteComponentProps } from 'react-router'
import { createBook } from 'domains/books/workflows/createBook'
import useDoc from 'lib/api/hooks/useDoc'
import { editBook } from 'domains/books/workflows/editBook'
import { hasValue } from 'lib/utils'
import { Spinner, Select, FormControl, FormLabel } from '@chakra-ui/core'

interface IProps {
  id?: string
  isCreate?: boolean
  authors?: IAuthor[]
  isOpen: boolean
  close: () => any
  goToBookDetail?: boolean
}

interface IFormValues {
  title: string
  type: 'Fiction' | 'Nonfiction'
  genre: BookGenres
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
})

export default function CreateEditBookModal(props: IProps) {
  const router = useContext(RouterContext) as RouteComponentProps

  const bookPath = `books/${props.id}`
  const [data] = useDoc(bookPath)
  let book: IBook | null = null
  if (data) {
    book = data as IBook
  }

  const onSave = async (
    values: IFormValues,
    bag: FormikActions<IFormValues>,
  ) => {
    if (props.isCreate) {
      const newBook = await createBook(values as IBook, props.authors!)
      bag.setSubmitting(false)
      bag.resetForm()
      if (props.goToBookDetail) {
        router.history.push(`/books/${newBook.id}`)
      }
    } else {
      await editBook(values as IBook)
      props.close()
    }
  }

  const initialValues: IFormValues = props.isCreate
    ? {
        title: '',
        type: 'Fiction',
        genre: 'Adventure' as BookGenres,
      }
    : book!
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, handleChange, submitForm, isSubmitting }) => (
        <ModalWrapper
          title={`${props.isCreate ? 'Add' : 'Edit'} Book`}
          isOpen={props.isOpen}
          pending={isSubmitting}
          actions={[
            {
              text: props.isCreate ? 'Add' : 'Save',
              variantColor: 'green',
              onClick: submitForm,
              extra: { type: 'submit' },
            },
            {
              text: 'Cancel',
              variantColor: 'gray',
              onClick: props.close,
              extra: { type: 'button' },
            },
          ]}
        >
          {props.id && !book && <Spinner />}
          {(props.isCreate || hasValue(book)) && (
            <Form>
              <FormInput
                label="Title"
                name="title"
                value={values.title || ''}
                onChange={handleChange}
                placeholder="A Game of Thrones"
              />

              <FormControl my={3}>
                <FormLabel htmlFor="type">Type</FormLabel>
                <Select
                  aria-labelledby="type"
                  id="type"
                  placeholder="Select Type"
                  value={values.type}
                  onChange={handleChange}
                  name="type"
                >
                  <option value="Fiction">Fiction</option>
                  <option value="Nonfiction">Nonfiction</option>
                </Select>
              </FormControl>

              <FormControl my={3}>
                <FormLabel htmlFor="genre">Genre</FormLabel>
                <Select
                  aria-labelledby="genre"
                  id="genre"
                  placeholder="Select Genre"
                  value={values.genre}
                  onChange={handleChange}
                  name="genre"
                >
                  {GenresList.map(genre => (
                    <option key={genre} value={genre}>
                      {genre}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Form>
          )}
        </ModalWrapper>
      )}
    </Formik>
  )
}
