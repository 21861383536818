import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Icon, Spinner, Heading, Text } from '@chakra-ui/core'

import Row from 'lib/components/Row'
import IAuthor from 'domains/authors/models/IAuthor'
import DeleteAuthorWrapper from 'domains/authors/components/DeleteAuthorWrapper'
import { displayAuthorName } from 'domains/authors/utils/authors'
import { formatDate } from 'lib/utils/date'
import { dotJoinItems } from 'lib/utils/strings'
import useDoc from 'lib/api/hooks/useDoc'

interface IProps extends IAuthor {
  allowDelete?: boolean
}

export default function AuthorRow(props: IProps) {
  const [data] = useDoc(`authors/${props.id}`)

  if (!data) {
    return <Spinner />
  }
  const author = data as IAuthor
  return (
    <Row>
      <Box flex={1} height="100%">
        <Link to={`/authors/${author.id}`}>
          <Box p={3}>
            <Heading fontSize={['lg', 'xl']}>
              {displayAuthorName(props)}
            </Heading>
            <Text mt={2} fontSize="xs">
              {dotJoinItems([
                `Created ${formatDate(author.createdAt)}`,
                `Modified ${formatDate(author.modifiedAt)}`,
              ])}
            </Text>
          </Box>
        </Link>
      </Box>

      {props.allowDelete && (
        <DeleteAuthorWrapper id={author.id}>
          <Icon mx={3} name="delete" />
        </DeleteAuthorWrapper>
      )}
    </Row>
  )
}
