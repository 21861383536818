import React, { PureComponent } from 'react'
import * as R from 'ramda'
import { Icon, Flex, Text } from '@chakra-ui/core'

import ModalWrapper from 'lib/components/ModalWrapper'

interface IProps {
  isOpen: boolean
  title?: string
  icon?: string
  message?: any
  confirmText?: string
  confirmVariant?: string
  denyText?: string
  onConfirm: (args?: object) => void
  onDeny?: () => void
  args?: object
  doubleConfirm?: boolean
}

export default class Dialog extends PureComponent<IProps> {
  state = {
    pending: false,
    confirmedOnce: false,
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.unSetPending()
    }
  }

  onConfirm = async () => {
    if (this.props.doubleConfirm && !this.state.confirmedOnce) {
      this.setState({ confirmedOnce: true })
    } else {
      this.setPending()
      await this.props.onConfirm(this.props.args)
    }
  }

  onDeny = async () => {
    this.setPending()
    if (this.props.onDeny) {
      await this.props.onDeny()
    }
  }

  getActions() {
    const { confirmText, confirmVariant, denyText, onDeny } = this.props
    const actions = [
      {
        text: confirmText || 'Yes',
        variantColor: confirmVariant || 'green',
        onClick: this.onConfirm,
      },
    ]
    if (!onDeny) {
      return actions
    }

    return [
      ...actions,
      {
        text: denyText || 'Cancel',
        variantColor: 'gray',
        onClick: this.onDeny,
      },
    ]
  }

  setPending = () => this.setState({ pending: true })

  unSetPending = () => this.setState({ pending: false })

  renderMessage() {
    const { message } = this.props
    if (!message) {
      return null
    }
    if (R.is(String, message)) {
      return <Text textAlign="center">{message}</Text>
    }
    if (R.is(Function, message)) {
      return message()
    }

    return message
  }

  render() {
    const { icon, message, title, isOpen } = this.props

    const titlePrefix = this.state.confirmedOnce ? 'NO SERIOUSLY, ' : ''
    return (
      <ModalWrapper
        isOpen={isOpen}
        title={`${titlePrefix}${title || 'Are you sure?'}`}
        actions={this.getActions()}
        pending={this.state.pending}
      >
        {(message || icon) && (
          <Flex direction="column" align="center">
            <Icon fontSize="86px" pb={4} data-test="modal-icon" name={icon} />
            {this.renderMessage()}
          </Flex>
        )}
      </ModalWrapper>
    )
  }
}
