import db, { getCollection } from 'lib/api/db'
import IAuthor from 'domains/authors/models/IAuthor'
import IBook from 'domains/books/models/IBook'
import { updateEntity } from 'lib/api/entities/updateEntity'

export const editBook = async (book: IBook) => {
  await updateEntity('books', book)
  const bookPath = `books/${book.id}`

  const bookAuthors = await getCollection(`${bookPath}/authors`)
  for (const author of bookAuthors as IAuthor[]) {
    await db.doc(`authors/${author.id}/books/${book.id}`).update(book)
  }
}
