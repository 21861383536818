import React from 'react'

import FormErrorMessage from 'lib/components/FormErrorMessage'
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  NumberInput,
} from '@chakra-ui/core'
import { convertToInt } from 'lib/utils'

interface IProps {
  label: string
  name: string
  placeholder?: string
  onChange: any
  onBlur?: any
  value: any
  type?: string
  isTextArea?: boolean
  isNumber?: boolean
  inputProps?: object
}

export default function FormInput(props: IProps) {
  const Component = props.isTextArea
    ? Textarea
    : props.isNumber
    ? NumberInput
    : Input
  const labelBy = `label-${props.name}`

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (props.isNumber) {
      props.onChange({
        ...e,
        currentTarget: {
          ...e.currentTarget,
          value: convertToInt(e.currentTarget.value),
        },
      })
    }
    props.onChange(e)
  }

  return (
    <FormControl my={3}>
      <FormLabel id={labelBy} htmlFor={props.name}>
        {props.label}
      </FormLabel>
      <Component
        name={props.name}
        aria-labelledby={labelBy}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        type={props.type}
        {...(props.inputProps || {})}
      />
      <FormErrorMessage name={props.name} />
    </FormControl>
  )
}
