import React, { useContext } from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'
import {
  Box,
  Heading,
  Icon,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Flex,
  Text,
} from '@chakra-ui/core'

import { RouterContext } from 'domains/application/screens/AppScreen'
import authentication from 'lib/api/authentication'
import { useWindowSize } from 'lib/hooks/useWindowSize'

export const HEADER_HEIGHT = 64
export const HEADER_HEIGHT_PX = `${HEADER_HEIGHT}px`

export default function Header() {
  const { isPhone } = useWindowSize()
  const router = useContext(RouterContext) as RouteComponentProps
  const onGoToRoute = (route: string) => () => router.history.push(route)

  const onLogout = async () => authentication().signOut()

  return (
    <Flex
      position="sticky"
      borderRadius={0}
      top={0}
      left={0}
      right={0}
      direction="row"
      justify="space-between"
      align="center"
      px={[2, 3]}
      py={0}
      height={HEADER_HEIGHT_PX}
      bg="white"
      zIndex={999}
    >
      <Flex
        height={HEADER_HEIGHT_PX}
        direction="row"
        align="center"
        cursor="pointer"
      >
        <Heading fontSize={['lg', 'xl', '2xl']} color="primary">
          Books App
        </Heading>

        <Box ml={4} />
        {!isPhone && (
          <Flex direction="row" align="center" height="100%">
            <Link to="/authors">
              <Text fontWeight={700} color="primary" px={4}>
                Authors
              </Text>
            </Link>
            <Link to="/books">
              <Text fontWeight={700} color="primary" px={4}>
                Books
              </Text>
            </Link>
          </Flex>
        )}
      </Flex>

      {isPhone && (
        <Menu>
          <MenuButton as={Button}>
            <Text>Menu</Text>
            <Icon name="chevron-down" ml={2} />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={onGoToRoute('/authors')}>Authors</MenuItem>
            <MenuItem onClick={onGoToRoute('/books')}>Books</MenuItem>
            <MenuDivider />
            <MenuItem onClick={onLogout}>Log out</MenuItem>
          </MenuList>
        </Menu>
      )}
    </Flex>
  )
}
