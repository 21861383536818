import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Flex, Text } from '@chakra-ui/core'

interface IProps {
  onFilesAdded: (files: File[]) => any
}

export default function FileDropzone(props: IProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    draggedFiles,
  } = useDropzone({ onDrop: props.onFilesAdded })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <Flex
        direction="column"
        align="center"
        justify="center"
        border="dashed 4px #ababab"
        p={4}
        minHeight={150}
        cursor="pointer"
      >
        {isDragActive ? (
          <Text fontWeight={700}>
            Drop {draggedFiles.length} file
            {draggedFiles.length > 1 ? 's' : ''}
          </Text>
        ) : (
          <Text>Drag 'n' drop some files here, or click to select files</Text>
        )}
      </Flex>
    </div>
  )
}
