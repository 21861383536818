import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'
// import { ThemeProvider } from 'styled-components'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import 'react-tabs/style/react-tabs.css'
import '@reach/dialog/styles.css'

import 'lib/styles/css/index.css'
import 'lib/styles/css/modals.css'
import theme from 'lib/styles/theme'
import StartupScreen from 'domains/application/screens/StartupScreen'

ReactModal.setAppElement('#modals')

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CSSReset />
    <StartupScreen />
  </ThemeProvider>,
  document.getElementById('root'),
)
