import * as R from 'ramda'
import algoliasearch from 'algoliasearch'

const ALGOLIA_APP_ID = '112CK3HPAN'
const ALGOLIA_SECRET = '6bd023426669591d9f25260306b6a21f'

export type SearchIndexes = 'authors' | 'narrators' | 'books' | 'series'

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SECRET)

export default searchClient

export const addSearchIndex = (type: SearchIndexes, value: any) =>
  searchClient
    .initIndex(type)
    .addObject(R.assoc('objectID', R.prop('id', value), value))

export const deleteSearchIndex = (type: SearchIndexes, id: string) =>
  searchClient.initIndex(type).deleteObject(id)

export const searchIndex = async (type: SearchIndexes, query: string) => {
  const results = await searchClient.initIndex(type).search({ query })
  return results.hits
}
