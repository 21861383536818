import React, { createContext, useContext } from 'react'
import {
  Redirect,
  Router,
  Route,
  Switch,
  RouteComponentProps,
} from 'react-router-dom'

import { history } from 'lib/utils/navigation'
import { UserContext } from 'domains/application/screens/StartupScreen'
import LoginScreen from 'domains/authentication/screens/LoginScreen'
import MainRouter from 'domains/application/screens/MainRouter'

export const RouterContext = createContext<RouteComponentProps | null>(null)

export default function AppScreen() {
  const currentUser = useContext(UserContext)

  const renderRoute = (routeProps: RouteComponentProps) => {
    return (
      <RouterContext.Provider value={routeProps}>
        <Switch location={routeProps.location}>
          {!currentUser && [
            <Route
              key="login"
              exact={true}
              path="/login"
              component={LoginScreen}
            />,
            <Redirect key="redirect" to="/login" />,
          ]}
          {currentUser && <MainRouter />}
        </Switch>
      </RouterContext.Provider>
    )
  }

  return (
    <main>
      <Router history={history}>
        <Route render={renderRoute} />
      </Router>
    </main>
  )
}
