import React, { useState } from 'react'
import { Box, Icon, Text, Heading, Flex } from '@chakra-ui/core'
import fileDownload from 'js-file-download'

import Row from 'lib/components/Row'
import IBookFile from 'domains/books/models/IBookFile'
import Dialog from 'lib/components/Dialog'
import { deleteBookFile } from 'domains/books/workflows/deleteBookFile'
import { dotJoinItems } from 'lib/utils/strings'
import { formatDate } from 'lib/utils/date'
import { hasFileExtension, MP3_EXTENSION, M4A_EXTENSION } from 'lib/utils/files'
import { useWindowSize } from 'lib/hooks/useWindowSize'
import ModalWrapper from 'lib/components/ModalWrapper'
import ProgressBar from 'lib/components/ProgressBar'

export default function BookFileRow(props: IBookFile) {
  const { isPhone } = useWindowSize()

  const [downloading, setDownloading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const onDeleteRequested = () => setDeleteOpen(true)
  const onDeleteDenied = () => setDeleteOpen(false)
  const onDeleteConfirmed = async () => {
    await deleteBookFile(props)
    setDeleteOpen(false)
  }

  if (!props.id) {
    return null
  }

  const onDownload = () => {
    setDownloading(true)
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onprogress = e => {
      setProgress(Math.round((e.loaded / e.total) * 100))
    }
    xhr.onload = () => {
      setDownloading(false)
      setProgress(0)
      fileDownload(xhr.response, props.name)
    }
    xhr.open('GET', props.url)
    xhr.send()
  }

  return (
    <Row>
      <Box width={30}>
        <Heading fontSize="xl" textAlign="center">
          {props.order}
        </Heading>
      </Box>
      <Box flex={1} height="100%" px={3} py={[2, 4]}>
        <Flex direction="row" align="center">
          <Box>
            <Text fontSize="md" wordBreak="break-all">
              {props.name}
            </Text>
            <Text mt={2} fontSize="xs">
              {dotJoinItems([
                `Created: ${formatDate(props.createdAt)}`,
                `Updated: ${formatDate(props.modifiedAt)}`,
              ])}
            </Text>
          </Box>
        </Flex>
      </Box>

      <Flex direction="row" align="center" py={[2, 4]}>
        {!isPhone &&
          hasFileExtension(props.url, [MP3_EXTENSION, M4A_EXTENSION], true) && (
            <audio controls={true} src={props.url} />
          )}

        <Box onClick={onDownload} cursor="pointer" mx={3}>
          <Icon name="download" />
        </Box>
        <Box cursor="pointer" onClick={onDeleteRequested} mx={3}>
          <Icon name="delete" />
        </Box>
      </Flex>

      {downloading && (
        <ModalWrapper
          isOpen={true}
          title={`Downloading ${props.name}`}
          actions={[]}
        >
          <ProgressBar percent={progress} />
        </ModalWrapper>
      )}

      {deleteOpen && (
        <Dialog
          title={`Delete ${props.name}`}
          isOpen={deleteOpen}
          icon="delete"
          confirmVariant="red"
          onConfirm={onDeleteConfirmed}
          onDeny={onDeleteDenied}
        />
      )}
    </Row>
  )
}
