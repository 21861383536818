import React from 'react'
import { Flex } from '@chakra-ui/core'
import { BoxProps } from '@chakra-ui/core/dist/Box'

interface IProps extends BoxProps {
  noClick?: boolean
  children: any
}

export default function Row(props: IProps) {
  const { children, noClick, ...rest } = props
  return (
    <Flex
      bg="white"
      direction="row"
      wrap={['wrap', 'nowrap']}
      justify={['flex-start', 'space-between']}
      align="center"
      cursor={noClick ? '' : 'clickable'}
      {...rest}
    >
      {children}
    </Flex>
  )
}
