import React, { useState } from 'react'

import Dialog from 'lib/components/Dialog'
import { deleteAuthor } from 'domains/authors/workflows/deleteAuthor'
import { Box } from '@chakra-ui/core'

interface IProps {
  id: string
  children: any
  afterDelete?: () => any
}

export default function DeleteAuthorWrapper(props: IProps) {
  const [isOpen, setIsOpen] = useState(false)
  const onDelete = (e: React.FormEvent<HTMLElement>) => {
    if (!isOpen) {
      e.preventDefault()
      setIsOpen(true)
    }
  }
  const onDeleteConfirmed = async () => {
    await deleteAuthor(props.id)
    if (props.afterDelete) {
      props.afterDelete()
    }
  }
  const onCloseDelete = () => setIsOpen(false)

  return (
    <Box onClick={onDelete} cursor="pointer">
      {props.children}
      {isOpen && (
        <Dialog
          title={'Delete Author?'}
          message="This will delete all books, series, and uploaded files."
          icon="delete"
          confirmVariant="red"
          onConfirm={onDeleteConfirmed}
          onDeny={onCloseDelete}
          isOpen={isOpen}
          doubleConfirm={true}
        />
      )}
    </Box>
  )
}
