import React, { useState } from 'react'
import { Box, Button, Spinner } from '@chakra-ui/core'

import List from 'lib/components/List'
import { RouteComponentProps } from 'react-router'
import useDoc from 'lib/api/hooks/useDoc'
import { getNavigationProp } from 'lib/utils/withNavigationProps'
import IBook from 'domains/books/models/IBook'
import AuthorRow from 'domains/authors/components/AuthorRow'
import BookFileRow from 'domains/books/comonents/BookFileRow'
import CreateBookFileModal from 'domains/books/modals/CreateBookFileModal'
import CreateEditBookWrapper from 'domains/books/comonents/CreateEditBookWrapper'
import DeleteBookWrapper from 'domains/books/comonents/DeleteBookWrapper'
import Paper from 'lib/components/Paper'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import FileDropzone from 'lib/components/FileDropzone'

const AuthorOrder = (ref: firebase.firestore.CollectionReference) =>
  ref.orderBy('lastName', 'asc')

const FileOrder = (ref: firebase.firestore.CollectionReference) =>
  ref.orderBy('order', 'asc')

export default function BookDetailsScreen(props: RouteComponentProps) {
  const id = getNavigationProp(props, 'id') as string
  const bookPath = `books/${id}`
  const [data] = useDoc(bookPath)

  const [createOpen, setCreateOpen] = useState(false)
  const onCreateNew = () => setCreateOpen(true)
  const onCloseCreate = () => setCreateOpen(false)
  const [files, setFiles] = useState<File[]>([])

  if (!data) {
    return <Spinner />
  }

  const book = data as IBook

  const onDeleted = () => props.history.replace('/books')

  const onFilesAdded = (addedFiles: File[]) => {
    setFiles(addedFiles)
    setCreateOpen(true)
  }

  return (
    <Box p={3}>
      <DocumentTitle title={book.title} />
      <Paper
        title={book.title}
        actions={[
          <CreateEditBookWrapper id={book.id} key="edit">
            <Button variantColor="blue" mr={2}>
              Edit
            </Button>
          </CreateEditBookWrapper>,
          <DeleteBookWrapper id={book.id} afterDelete={onDeleted} key="delete">
            <Button variantColor="red" mr={2}>
              Delete
            </Button>
          </DeleteBookWrapper>,
        ]}
      >
        <List
          title="Authors"
          collection={`${bookPath}/authors`}
          RowComponent={AuthorRow}
          query={AuthorOrder}
        />

        <List
          title="Files"
          actions={
            <Button variantColor="blue" onClick={onCreateNew}>
              Add File(s)
            </Button>
          }
          collection={`${bookPath}/files`}
          RowComponent={BookFileRow}
          query={FileOrder}
        />

        <FileDropzone onFilesAdded={onFilesAdded} />
      </Paper>

      <CreateBookFileModal
        isOpen={createOpen}
        book={book}
        close={onCloseCreate}
        files={files}
      />
    </Box>
  )
}
