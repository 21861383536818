import * as firebase from 'firebase'
import './connection'
import moment from 'moment'

const db = firebase.firestore()
export default db

export const getDoc = async (path: string) => {
  const ref = await db.doc(path).get()
  return ref ? { id: ref.id, ...ref.data() } : null
}

export const getCollection = async (path: string) => {
  const ref = await db.collection(path).get()
  const collection: any[] = []
  if (!ref) {
    return null
  }
  ref.forEach(snap =>
    collection.push({
      id: snap.id,
      ...snap.data(),
    }),
  )

  return collection
}

export const getTimeStamp = () => moment.utc().valueOf()
