import { useState, useEffect } from 'react'

export interface IWindowSize {
  width: number
  height: number
  isPhone: boolean
}
export function useWindowSize() {
  const isClient = typeof window === 'object'

  function getSize() {
    const width = isClient ? window.innerWidth : 320
    return {
      width,
      height: isClient ? window.innerHeight : 600,
      isPhone: width < 600,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize as IWindowSize
}
