import * as historyLib from 'history'
import * as R from 'ramda'

export const getNavigationparam = (
  props: object,
  param: string,
  fallback: any = null,
) => R.pathOr(fallback, ['match', 'params', param], props)

export const navigationParamSelector = (
  param: string,
  fallback: any = null,
) => (_: any, props: object) => getNavigationparam(props, param, fallback)

const historySingleton = historyLib.createBrowserHistory()

export const history = historySingleton

export const goBack = (n = 1) => historySingleton.go(0 - n)

export const navigate = (route: string, state = {}) =>
  historySingleton.push(route, state)

export const replace = (route: string, state = {}) =>
  historySingleton.replace(route, state)
