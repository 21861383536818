import React, { useState } from 'react'
import { Box } from '@chakra-ui/core';

import CreateEditBookModal from 'domains/books/modals/CreateEditBookModal'
import IAuthor from 'domains/authors/models/IAuthor'

interface IProps {
  id?: string
  isCreate?: boolean
  children: any
  goToBookDetail?: boolean
  after?: () => any
  authors?: IAuthor[]
}

export default function CreateEditBookWrapper(props: IProps) {
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = (e: React.FormEvent<HTMLElement>) => {
    if (!isOpen) {
      e.preventDefault()
      setIsOpen(true)
    }
  }
  const onClose = () => setIsOpen(false)

  return (
    <Box onClick={onOpen} cursor="pointer">
      {props.children}
      {isOpen && (
        <CreateEditBookModal
          id={props.id}
          isCreate={props.isCreate}
          isOpen={isOpen}
          close={onClose}
          goToBookDetail={props.goToBookDetail}
          authors={props.authors}
        />
      )}
    </Box>
  )
}
