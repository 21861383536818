import React from 'react'
import { Image, Spinner, Flex } from '@chakra-ui/core'

export default function AppLoadingScreen() {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      width="100vw"
      height="100vh"
      bg="white"
    >
      <Image src="/images/icon.png" width={300} height={300} />

      <Spinner />
    </Flex>
  )
}
