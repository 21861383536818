import React, { useContext } from 'react'
import { Formik, FormikActions, Form } from 'formik'
import * as Yup from 'yup'

import ModalWrapper from 'lib/components/ModalWrapper'
import FormInput from 'lib/components/FormInput'
import { RouterContext } from 'domains/application/screens/AppScreen'
import { RouteComponentProps } from 'react-router'
import { createEntity } from 'lib/api/entities/createEntity'
import useDoc from 'lib/api/hooks/useDoc'
import IAuthor from 'domains/authors/models/IAuthor'
import { editAuthor } from 'domains/authors/workflows/editAuthor'
import { hasValue } from 'lib/utils'
import { Spinner } from '@chakra-ui/core'

interface IProps {
  id?: string
  isCreate?: boolean
  isOpen: boolean
  close: () => any
}

interface IFormValues {
  firstName: string
  lastName: string
  middleInitial: string
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
})

export default function CreateEditAuthorModal(props: IProps) {
  const router = useContext(RouterContext) as RouteComponentProps

  const authorPath = `authors/${props.id}`
  const [data] = useDoc(authorPath)
  let author: IAuthor | null = null
  if (data) {
    author = data as IAuthor
  }

  const onSave = async (
    values: IFormValues,
    bag: FormikActions<IFormValues>,
  ) => {
    if (props.isCreate) {
      const newAuthor = await createEntity('authors', values)
      bag.setSubmitting(false)
      router.history.push(`/authors/${newAuthor.id}`)
    } else {
      await editAuthor(values as IAuthor)
      bag.setSubmitting(false)
      bag.resetForm()
      props.close()
    }
  }

  const initialValue: IFormValues = props.isCreate
    ? {
        firstName: '',
        lastName: '',
        middleInitial: '',
      }
    : author!

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, handleChange, submitForm, isSubmitting }) => (
        <ModalWrapper
          title={`${props.isCreate ? 'Add' : 'Edit'} Author`}
          pending={isSubmitting}
          isOpen={props.isOpen}
          isForm={true}
          actions={[
            {
              text: props.isCreate ? 'Add' : 'Update',
              variantColor: 'green',
              onClick: submitForm,
              extra: { type: 'submit' },
            },
            {
              text: 'Cancel',
              variantColor: 'gray',
              onClick: props.close,
              extra: { type: 'button' },
            },
          ]}
        >
          {props.id && !author && <Spinner />}
          {(props.isCreate || hasValue(author)) && (
            <>
              <FormInput
                label="First Name"
                name="firstName"
                value={values.firstName || ''}
                onChange={handleChange}
                placeholder="George"
              />
              <FormInput
                label="Middle Initial"
                name="middleInitial"
                value={values.middleInitial || ''}
                onChange={handleChange}
                placeholder="Optional"
              />
              <FormInput
                label="Last Name"
                name="lastName"
                value={values.lastName || ''}
                onChange={handleChange}
                placeholder="Martin"
              />
            </>
          )}
        </ModalWrapper>
      )}
    </Formik>
  )
}
