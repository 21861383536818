import * as R from 'ramda'

import db, { getTimeStamp } from 'lib/api/db'
import { addSearchIndex } from 'lib/api/search'
import { EntityType } from 'lib/api/entities/entityConstants'

export const updateEntity = async (type: EntityType, value: any) => {
  const id = R.prop('id', value)
  const entity = {
    ...value,
    modifiedAt: getTimeStamp(),
  }
  await db.doc(`${type}/${id}`).update(entity)
  await addSearchIndex(type, entity)
}
