import React, { useState, useEffect, createContext } from 'react'

import AppLoadingScreen from 'domains/application/screens/AppLoadingScreen'
import AppScreen from 'domains/application/screens/AppScreen'
import authentication from 'lib/api/authentication'
import IUser from 'domains/authentication/models/IUser'
import db from 'lib/api/db'

export const UserContext = createContext<IUser | boolean>(false)

export default function StartupScreen() {
  const [user, setUser] = useState<IUser | null | boolean>(null)

  useEffect(() => {
    authentication().onAuthStateChanged(async (authUser: any) => {
      if (authUser) {
        const dbUser = await db.doc(`users/${authUser.uid}`).get()
        setUser(dbUser.data() as IUser)
      } else {
        setUser(false)
      }
    })
  }, [])

  if (user === null) {
    return <AppLoadingScreen />
  }

  return (
    <UserContext.Provider value={user}>
      <AppScreen />
    </UserContext.Provider>
  )
}
