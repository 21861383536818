import React from 'react'
import { Box, Heading, Flex } from '@chakra-ui/core'

interface IProps {
  title: string
  actions?: any
  children: any
}

export default function Paper(props: IProps) {
  return (
    <Box rounded="lg" bg="white" p={3}>
      <Flex
        direction="row"
        wrap={['wrap', 'nowrap']}
        justify={['flex-start', 'space-between']}
        align="center"
        p={3}
      >
        <Heading fontSize={['lg', '3xl']}>{props.title}</Heading>

        <Flex direction="row" align="center" my={[2, 0]}>
          {props.actions}
        </Flex>
      </Flex>

      {props.children}
    </Box>
  )
}
