import { theme as chakraTheme } from '@chakra-ui/core'
import { flexibleStyles } from 'lib/styles/flexibleStyles'

const theme = {
  ...chakraTheme,
  flexibleStyles,
  colors: {
    ...chakraTheme.colors,
    primary: '#00aeee',
  },
}
export default theme
