import React, { useState } from 'react'
import { Box, Button } from '@chakra-ui/core'

import List from 'lib/components/List'
import Paper from 'lib/components/Paper'
import { RouteComponentProps } from 'react-router'
import AuthorRow from 'domains/authors/components/AuthorRow'
import CreateEditAuthorModal from 'domains/authors/modals/CreateEditAuthorModal'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'

const order = (ref: firebase.firestore.CollectionReference) =>
  ref.orderBy('lastName', 'asc')

export default function AuthorsScreen(props: RouteComponentProps) {
  const [createOpen, setCreateOpen] = useState(false)
  const onCreateNew = () => setCreateOpen(true)
  const onCloseCreate = () => setCreateOpen(false)

  return (
    <Box p={3}>
      <DocumentTitle title="All Authors" />
      <Paper
        title="Authors"
        actions={
          <Button onClick={onCreateNew} variantColor="green">
            Add Author
          </Button>
        }
      >
        <List
          collection="authors"
          RowComponent={AuthorRow}
          rowProps={{ allowDelete: true }}
          query={order}
          searchIndex="authors"
        />
      </Paper>

      <CreateEditAuthorModal
        isCreate={true}
        isOpen={createOpen}
        close={onCloseCreate}
      />
    </Box>
  )
}
